<template>
<AppLayout>
    <template v-slot:appContent>
        <div>
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
                <h4 class="">Lab User/Create</h4>
                <div>
                    <router-link :to="{...previousRoute}">
                        <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
                    </router-link>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div v-if="!userSearchStatus" class="position-relative" style="min-height: 60vh">
                            <div class="position-absolute " style="top: 50%;transform:translateY(-50%); left: 0;right: 0">
                                <h5 class="text-center mb-2">To create an lab user on the system, start by first inputting the specified email address.</h5>
                                <div class="row center-div mx-auto">
                                    <div class="col-lg-10 col-sm-12">
                                        <div class="position-relative has-icon-left">
                                            <input type="text" id="userSearch" class="form-control" v-model="userSearchQuery" placeholder="Type full email address">
                                            <div class="form-control-position">
                                                <i class='bx bx-search-alt'></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 mt-2 mt-lg-0">
                                        <button @click="searchUser" type="button" class="btn btn-block btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row">
                                <!-- left side numbering steps of the process start. -->
                                <div class="col-lg-2 step-divider">
                                    <div class="d-flex align-items-center cursor-pointer">
                                        <div :class="tabStep >= 1 ? 'active-step' : 'inactive-step'" class="step-number d-flex justify-content-center align-items-center flex-shrink-0">1</div>
                                        <div class="ml-1" :class="tabStep >= 1 ? 'text-primary' : ''">User Details</div>
                                    </div>
                                    <div class="step-line d-flex justify-content-center ">
                                        <div :class="tabStep > 1 ? 'inner-line-primary-border' : 'inner-line-secondary-border'" class="inner-line "></div>
                                    </div>
                                    <div class="d-flex align-items-center cursor-pointer">
                                        <div :class="tabStep >= 2 ? 'active-step' : 'inactive-step'" class="step-number d-flex justify-content-center align-items-center flex-shrink-0">2</div>
                                        <div class="ml-1" :class="tabStep >= 2 ? 'text-primary' : ''">Address Details</div>
                                    </div>
                                    <div class="step-line d-flex justify-content-center ">
                                        <div :class="tabStep > 2 ? 'inner-line-primary-border' : 'inner-line-secondary-border'" class="inner-line "></div>
                                    </div>
                                    <div class="d-flex align-items-center cursor-pointer">
                                        <div :class="tabStep >= 3 ? 'active-step' : 'inactive-step'" class="step-number d-flex justify-content-center align-items-center flex-shrink-0">4</div>
                                        <div class="ml-1" :class="tabStep >= 3 ? 'text-primary' : ''">Preview Details</div>
                                    </div>
                                </div>
                                <!-- left side numbering steps of the process end. -->
                                <!-- right side response steps of the process starts. -->
                                <div class="col-lg-10 mt-3 mt-lg-0">
                                    <div class="row" v-if="tabStep===1">
                                        <div class="col-12">
                                            <h5>User Information</h5>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>First name *</label>
                                                <input v-model="postUserData.first_name" :readonly="userFound" type="text" class="form-control " placeholder="Enter first name">
                                                <div class="text-danger" v-if="errors.user.firstName">{{ errors.user.firstName }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Last name *</label>
                                                <input v-model="postUserData.last_name" :readonly="userFound" type="text" class="form-control " placeholder="Enter last name">
                                                <div class="text-danger" v-if="errors.user.lastName">{{ errors.user.lastName }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Phone Number *</label>
                                                <input v-model="postUserData.phone_number" :readonly="userFound" max="10" type="tel" class="form-control " placeholder="ie. 0412345678">
                                                <div class="text-danger" v-if="errors.user.phoneNumber">{{ errors.user.phoneNumber }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Email Address *</label>
                                                <input v-model="postUserData.email" :readonly="userFound || userEmailFilled" type="email" class="form-control" placeholder="ie. example@example.com.au" aria-describedby="userAlreadyExistWarning">
                                                <small v-if="userFound" id="userAlreadyExistWarning" class="form-text text-warning d-flex flex-row"><strong><i class='bx bx-info-circle'></i></strong>&nbsp;Previously registered user data cannot be changed to the current form.</small>
                                                <div class="text-danger" v-if="errors.user.email">{{ errors.user.email }}</div>
                                                <div class="text-danger" v-if="errors.address.userId">{{ errors.address.userId }}</div>
                                                <div class="text-danger" v-if="errors.labUser.userId">{{ errors.labUser.userId }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Date of Birth</label>
                                                <input v-if="userFound" v-model="postUserData.date_of_birth" :readonly="userFound" type="date" class="form-control">
                                                <div v-else class="d-flex align-items-center date-picker-wrapper">
                                                    <div class="d-flex align-items-center">
                                                        <i class='bx bx-calendar'></i>
                                                    </div>
                                                    <div class="w-100 pl-1 ">
                                                        <div class="w-100 ">
                                                            <DatePicker v-if="!userFound" v-model="postUserData.date_of_birth" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}" :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                                                <template v-slot="{ inputValue, inputEvents }">
                                                                    <input :value="inputValue" v-on="inputEvents" class="form-control" />
                                                                </template>
                                                            </DatePicker>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-danger" v-if="errors.user.dateOfBirth">{{ errors.user.dateOfBirth }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>System Using Role</label>
                                                <VueMultiselect :disabled="userFound" v-model="selectedRole" :options="role" :close-on-select="true" placeholder="Select state" label="name" track-by="value" :show-labels="false" :allow-empty="false" />
                                                <div class="text-danger" v-if="errors.user.roleId">{{ errors.user.roleId }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex justify-content-end">
                                                <button @click="jumpToStart" class="btn btn-light-primary">Prev</button>
                                                <button @click="userId ? tabNext() : createSingleUser()" :disabled="!isPostUserDataFilled" class="btn btn-light-primary ml-1">Next</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="tabStep===2">
                                        <div class="col-12">
                                            <h5>Address Information</h5>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Address</label>
                                                <small v-if="!addressInformationSelectDiv && hasUserAddresses" @click="addressInformationSelectDiv = !addressInformationSelectDiv" class="text-primary">&nbsp;(Click to select address from existing one)</small>
                                                <small v-if="addressInformationSelectDiv" @click="addressInformationSelectDiv = !addressInformationSelectDiv" class="text-primary">&nbsp;(Click to create a new address)</small>
                                                <VueMultiselect v-if="addressInformationSelectDiv" v-model="selectedAddress" :options="addresses" :close-on-select="true" placeholder="Select Address" label="name" track-by="value" :show-labels="false" :allow-empty="false" />
                                                <vue-google-autocomplete v-else :enable-geolocation="true" ref="address" :country="['au']" id="map" classname="form-control" placeholder="Please type full address." v-on:placechanged="googleMapApiResponseData" />
                                                <div class="text-danger" v-if="errors.labUser.addressId">{{ errors.labUser.addressId }}</div>
                                            </div>
                                        </div>
                                        <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 d-flex flex-row bx-flashing text-warning">
                                            <i class='bx bx-info-circle' style="line-height: 1.5rem !important;"></i>
                                            <span>&nbsp;Please modify the following address fields, if there anything wrong!</span>
                                        </div>
                                        <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-md-12">
                                            <div class="form-group">
                                                <label>Street Address *</label>
                                                <input v-model="postAddressData.street" type="text" class="form-control" placeholder="Enter street address" />
                                                <div class="text-danger" v-if="errors.address.street">{{ errors.address.street }}</div>
                                            </div>
                                        </div>
                                        <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6 ">
                                            <div class="form-group">
                                                <label>Suburbs/ Territory *</label>
                                                <input v-model="postAddressData.suburb" type="text" class="form-control" placeholder="Enter suburbs/ territory" />
                                                <div class="text-danger" v-if="errors.address.suburb">{{ errors.address.suburb }}</div>
                                            </div>
                                        </div>
                                        <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                                            <div class="form-group">
                                                <label>State *</label>
                                                <VueMultiselect v-model="selectedState" :allowEmpty="false" :options="state" :close-on-select="true" placeholder="Select state" label="name" track-by="value" :show-labels="false" />
                                                <div class="text-danger" v-if="errors.address.state">{{ errors.address.state }}</div>
                                            </div>
                                        </div>
                                        <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                                            <div class="form-group">
                                                <label>Post Code *</label>
                                                <input v-model="postAddressData.post_code" type="text" class="form-control" placeholder="Enter code" required />
                                                <div class="text-danger" v-if="errors.address.postCode">{{ errors.address.postCode }}</div>
                                            </div>
                                        </div>
                                        <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                                            <div class="form-group">
                                                <label>Country *</label>
                                                <input v-model="postAddressData.country" type="text" class="form-control" readonly required />
                                                <div class="text-danger" v-if="errors.address.country">{{ errors.address.country }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex justify-content-end">
                                                <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                                                <button class="btn btn-light-primary ml-1" :disabled="(addressInformationSelectDiv && !selectedAddress.value) || (!addressInformationSelectDiv &&!hasAddressData)" @click="addressInformationSelectDiv ? tabNext() : createSingleAddress()">Next</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="tabStep ===3">
                                        <div class="col-12">
                                            <h3>Preview Details</h3>
                                        </div>
                                        <div class="col-12">
                                            <table class="table table-responsive table-borderless table-transparent">
                                                <tbody>
                                                    <tr>
                                                        <td class="bold">First Name</td>
                                                        <td>:&nbsp;{{ postUserData.first_name }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bold">Last Name</td>
                                                        <td>:&nbsp;{{ postUserData.last_name }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bold">Phone Number</td>
                                                        <td>:&nbsp;{{ postUserData.phone_number }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bold">Email</td>
                                                        <td>:&nbsp;{{ postUserData.email }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bold">Date of Birth</td>
                                                        <td>:&nbsp;{{ postUserData.date_of_birth }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bold">Address</td>
                                                        <td>:&nbsp;{{addressInformationSelectDiv ?  selectedAddress.name : `${postAddressData.street}, ${postAddressData.suburb} ${postAddressData.state} ${postAddressData.post_code}, ${postAddressData.country}` }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="text-danger" v-if="errors.labUser.status">{{ errors.labUser.status }}</div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex justify-content-end">
                                                <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                                                <button class="btn btn-light-primary ml-1" @click="createLabUser">Create Lab User</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- right side response steps of the process end. -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
</AppLayout>
</template>

<script>
// component
import AppLayout from '@/layouts/backEnd/AppLayout';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

// core package
import {
    mapActions,
    mapGetters
} from "vuex";

// package
import {
    DatePicker
} from 'v-calendar';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import VueMultiselect from 'vue-multiselect';

export default {
    name: "LabUserCreate",
    mixins: [ShowToastMessage, Loader],

    components: {
        AppLayout,
        DatePicker,
        VueGoogleAutocomplete,
        VueMultiselect,
    },

    data() {
        return {
            userSearchQuery: '',
            userSearchStatus: false,
            userFound: false,
            userEmailFilled: false,
            tabStep: 1,
            userId: null,

            postUserData: {
                role_id: '',
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                date_of_birth: '',
            },

            selectedRole: {
                value: '',
                name: 'None'
            },

            addressInformationSelectDiv: false,
            hasUserAddresses: false,

            selectedAddress: {
                value: '',
                name: 'None'
            },

            postAddressData: {
                user_id: '',
                street: '',
                suburb: '',
                state: '',
                post_code: '',
                country: '',
            },

            selectedState: {
                value: '',
                name: 'None'
            },

            postLabUserData: {
                user_id: '',
                address_id: '',
                status: 1,
            },
            errors: {
                user: {
                    roleId: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    dateOfBirth: '',
                },
                address: {
                    userId: '',
                    street: '',
                    suburb: '',
                    state: '',
                    postCode: '',
                    country: '',
                },
                labUser: {
                    userId: '',
                    addressId: '',
                    status: '',
                },
            },

            getSettingsParams: {
                type: ['default'],
                key: ['default_state'],
            },
            getRolesParams: {
                type: 4 // lab user 
            },
            getUsersParams: {
                email: '',
                with_relation: ['role', 'addresses'],
            },
        }
    },

    computed: {
        ...mapGetters({
            previousRoute: 'previousRoute',
            users: 'appUsers/users',
        }),

        role() {
            let activeRoles = this.$store.getters['appRoles/roles'].map((roleObj) => {
                let roleId = roleObj.id;
                let roleName = roleObj.name;

                return {
                    value: roleId,
                    name: roleName
                };
            });

            return [{
                value: '',
                name: 'None'
            }, ...activeRoles];
        },

        isPostUserDataFilled() {
            return !!(this.postUserData.first_name && this.postUserData.last_name && (this.postUserData.email || this.postUserData.phone_number));
        },

        addresses() {
            let userAddresses = this.users && this.users[0] && this.users[0].addresses ? this.users[0].addresses.map((addressObj) => {
                let addressId = addressObj.id;
                let addressStreet = addressObj.street;
                let addressSuburb = addressObj.suburb;
                let addressState = addressObj.state.toUpperCase();
                let addressPostCode = addressObj.post_code;
                let addressCountry = addressObj.country;

                return {
                    value: addressId,
                    name: `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`
                };
            }) : [];

            return [{
                    value: '',
                    name: 'None'
                },
                ...userAddresses
            ];
        },

        state() {
            return this.$store.getters['appSettings/settingDefaultState'].value
        },

        hasAddressData() {
            return !!(this.postAddressData.street || this.postAddressData.suburb || this.postAddressData.state || this.postAddressData.post_code);
        },

        isPostAddressDataFilled() {
            return !!(this.postAddressData.street && this.postAddressData.suburb && this.postAddressData.state && this.postAddressData.post_code && this.postAddressData.country);
        },

    },

    watch: {
        selectedRole(selectedRole) {
            this.postUserData.role_id = selectedRole && selectedRole.value ? selectedRole.value : '';
        },

        selectedAddress(selectedAddress) {
            this.postLabUserData.address_id = selectedAddress.value ?? null;
        },
    },

    methods: {
        ...mapActions({
            resetUsers: 'appUsers/resetUsers',
            resetUser: 'appUsers/resetUser',
            resetRole: 'appRoles/resetRole',
            resetAddress: 'appAddresses/resetAddress',
            resetLabUser: 'appLabUsers/resetLabUser',

            postUser: 'appUsers/postUser',
            postAddress: 'appAddresses/postAddress',

            postLabUser: 'appLabUsers/postLabUser',

            getSettings: 'appSettings/getSettings',
            getRoles: 'appRoles/getRoles',
            getUsers: 'appUsers/getUsers',
        }),

        async tabPrev() {
            this.tabStep--;
        },

        async tabNext() {
            this.tabStep++;
        },

        async tabJump(tabToJump) {
            this.tabStep = tabToJump;
        },

        async resetPostUserData() {
            this.postUserData = {
                role_id: '',
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                date_of_birth: '',
            };
        },

        async resetSelectedRole() {
            this.selectedRole = {
                value: '',
                name: 'None'
            };
        },

        async resetSelectedAddress() {
            this.selectedAddress = {
                value: '',
                name: 'None'
            };
        },

        async resetErrors() {
            this.errors = {
                user: {
                    roleId: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    dateOfBirth: '',
                },
                address: {
                    userId: '',
                    street: '',
                    suburb: '',
                    state: '',
                    postCode: '',
                    country: '',
                },
                labUser: {
                    userId: '',
                    addressId: '',
                    status: '',
                },
            };
        },

        async jumpToStart() {
            this.userSearchStatus = false;
            this.userFound = false;
            this.userEmailFilled = false;
            this.tabStep = 1;
            this.userId = null;

            await this.resetUsers();
            await this.resetUser();
            await this.resetPostUserData();
            await this.resetSelectedRole();
            await this.resetSelectedAddress();
            await this.resetErrors();

            this.addressInformationSelectDiv = false;
            this.hasUserAddresses = false;

        },

        async searchUser() {
            this.loader(true);

            this.userSearchStatus = true;
            this.getUsersParams.email = this.userSearchQuery;
            await this.getUsers(this.getUsersParams);

            if (this.users.length > 0) {
                this.userFound = true;

                let user = this.users[0];
                this.userId = user.id;

                this.postUserData.role_id = user.role && user.role.id ? user.role.id : '';
                this.postUserData.first_name = user.first_name;
                this.postUserData.last_name = user.last_name;
                this.postUserData.email = user.email;
                this.postUserData.phone_number = user.phone_number;
                this.postUserData.date_of_birth = user.date_of_birth;

                this.postAddressData.user_id = this.userId;
                this.postLabUserData.user_id = this.userId;

                if (user.role) {
                    this.selectedRole.value = user.role.id;
                    this.selectedRole.name = user.role.name;
                }
            }

            if (this.users.length <= 0) {
                this.postUserData.email = this.userSearchQuery;
                this.userEmailFilled = true;
            }

            if (this.addresses.length > 1) {
                this.addressInformationSelectDiv = true;
                this.hasUserAddresses = true;
            }

            this.loader(false);
        },

        async createSingleUser() {

            if (!this.userId) {
                this.loader(true);
                await this.postUser(this.postUserData).then(async (response) => {
                    if (response.status === 200 || response.status === 201) {
                        this.userId = this.$store.getters['appUsers/user'].id;

                        this.postAddressData.user_id = this.userId;
                        this.postLabUserData.user_id = this.userId;

                        await this.tabNext();
                    }

                    this.errors.user.roleId = response.errors && response.errors.role_id ? response.errors.role_id[0] : '';
                    this.errors.user.firstName = response.errors && response.errors.first_name ? response.errors.first_name[0] : '';
                    this.errors.user.lastName = response.errors && response.errors.last_name ? response.errors.last_name[0] : '';
                    this.errors.user.email = response.errors && response.errors.email ? response.errors.email[0] : '';
                    this.errors.user.phoneNumber = response.errors && response.errors.phone_number ? response.errors.phone_number[0] : '';
                    this.errors.user.dateOfBirth = response.errors && response.errors.date_of_birth ? response.errors.date_of_birth[0] : '';

                    if (response.message) {
                        this.showToastMessage(response);
                    }
                });
                this.loader(false);
            }
        },

        googleMapApiResponseData: function (googleMapApiResponseData) {

            this.loader(true);
            let streetNumber = googleMapApiResponseData.street_number !== undefined ? googleMapApiResponseData.street_number : '';
            let streetName = googleMapApiResponseData.route !== undefined ? googleMapApiResponseData.route : '';
            let suburbs = googleMapApiResponseData.locality !== undefined ? googleMapApiResponseData.locality : '';
            let state = googleMapApiResponseData.administrative_area_level_1 !== undefined ? googleMapApiResponseData.administrative_area_level_1 : '';
            let postCode = googleMapApiResponseData.postal_code !== undefined ? googleMapApiResponseData.postal_code : '';
            let country = googleMapApiResponseData.country !== undefined ? googleMapApiResponseData.country : '';

            this.postAddressData.street = streetNumber ? `${streetNumber} ${streetName}` : streetName;
            this.postAddressData.suburb = suburbs;
            this.postAddressData.state = state.toLowerCase();
            this.postAddressData.post_code = postCode;
            this.postAddressData.country = country;

            if (state) {
                let addressStateIndex = this.state.findIndex(item => item.value === state.toLowerCase());
                if (addressStateIndex !== -1) {
                    let addressState = this.state[addressStateIndex];

                    this.selectedState.value = addressState.value;
                    this.selectedState.name = addressState.name;
                }
            }

            this.loader(false);

        },

        async createSingleAddress() {
            this.loader(true);
            await this.postAddress(this.postAddressData).then(async (response) => {
                if (response.status === 200 || response.status === 201) {
                    let address = this.$store.getters['appAddresses/address'];
                    let newAddress = {
                        value: this.$store.getters['appAddresses/address'].id,
                        name: `${address.street}, ${address.suburb} ${address.state} ${address.post_code}, ${address.country}`
                    };

                    this.postLabUserData.address_id = address.id;
                    this.addresses.push(newAddress);
                    this.hasUserAddresses = true;

                    await this.tabNext();
                }

                this.errors.address.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
                this.errors.address.street = response.errors && response.errors.street ? response.errors.street[0] : '';
                this.errors.address.suburb = response.errors && response.errors.suburb ? response.errors.suburb[0] : '';
                this.errors.address.state = response.errors && response.errors.state ? response.errors.state[0] : '';
                this.errors.address.postCode = response.errors && response.errors.post_code ? response.errors.post_code[0] : '';
                this.errors.address.country = response.errors && response.errors.country ? response.errors.country[0] : '';

                if (response.message) {
                    this.showToastMessage(response);
                }

                if (this.errors.address.userId) {
                    await this.tabJump(1);
                }

            });
            this.loader(false);
        },

        async createLabUser() {
            this.loader(true);
            await this.postLabUser(this.postLabUserData).then(async (response) => {
                if (response.status === 201) {
                    this.loader(false);
                    const toastObj = {
                        message: 'Lab user created successful.',
                        type: 'success'
                    };
                    this.showToastMessage(toastObj);

                    await this.$router.replace({
                        name: 'appLabUserList',
                    });
                    return;
                }
                this.loader(false);

                this.errors.labUser.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
                this.errors.labUser.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
                this.errors.labUser.status = response.errors && response.errors.status ? response.errors.status[0] : '';

                if (response.message) {
                    this.showToastMessage(response);
                }

                if (this.errors.labUser.userId) {
                    await this.tabJump(1);
                    return;
                }

                if (this.errors.labUser.addressId) {
                    await this.tabJump(2);
                }
            });

        },

        async getSettingList() {
            await this.getSettings(this.getSettingsParams);
        },

        async getRoleList() {
            await this.getRoles(this.getRolesParams);
        },
    },

    async mounted() {
        await this.getSettingList();
        await this.getRoleList();
    },

    async beforeMount() {
        await this.resetUser();
        await this.resetUsers();
        await this.resetRole();
        await this.resetAddress();
        await this.resetLabUser();
    }
}
</script>

<style scoped>
.center-div {
    width: 85%;
}

.step-number {
    width: 38px;
    height: 38px;
    color: white;
    border-radius: 0.375rem
}

.step-line {
    width: 38px;
    height: 38px;

}

.inner-line {
    display: inline-block !important;
    /* border-left: 1px solid #5a8dee; */
    height: 100%;
}

.inner-line-primary-border {
    border-left: 1px solid #5a8dee;
}

.inner-line-secondary-border {
    border-left: 1px solid gray;
}

.active-step {
    background-color: #5a8dee;
}

.inactive-step {
    background-color: #90a5cc;
}

.step-divider {
    border-right: 1px solid #d4d8dd;
}

@media only screen and (max-width: 600px) {
    .center-div {
        width: 100% !important;
    }
}

@media only screen and (max-width: 991px) {
    .step-divider {
        border-right: none;
        border-bottom: 1px solid #d4d8dd;
        padding-bottom: 27px;
    }
}
</style>
